
import {Text,Flex, Box,Heading,SimpleGrid} from '@chakra-ui/react'
//import Link from '../components/gatsbylink'
import MeinSEO from '../components/SEO'
import React from 'react'
import {StaticImage } from 'gatsby-plugin-image'

const Prolog = () => {
   return(
      <>
      <MeinSEO title="Prolog" description="Deklaration des Theaters Willy Praml" 
      keywords="Willy Praml, Theaterfestival in der Pandemie"
      />
      <Box  px="2" maxWidth="1100">
      <Heading mb="-2" mt="14" variant="titel">Prolog</Heading>  
      <SimpleGrid gap={3} columns={[1,1,2]}>
       <Box>  
      <Text>
       <span style={{fontWeight:500}}>Seit 2015 gibt es das Theaterfestival im Oberen Mittelrheintal</span>, das abwechselnd Orte an beiden Ufern dieses Abschnitts des Rheins bespielt. <br/>
      Ausgangspunkt und Schwerpunkt des Festivals war und ist die <span style={{fontWeight:500}}>Stadt Bacharach</span>. <br/>Kein Geringerer als <span style={{fontWeight:500}}>Heinrich Heine</span> war es, der diesem Rheinstädtchen die literarischen Weihen verliehen hat. <br/>Seine Erzählung 
      <span style={{fontWeight:500}}> DER RABBI VON BACHERACH</span> greift eine hier angesiedelte antijüdische Ritualmordlegende auf, die seit dem Mittelalter zur Anheizung von Pogromen
       an der jüdischen Bevölkerung im gesamten Rheintal diente, bis zuletzt im vergangenen Jahrhundert mit dem Holocaust die systematische Auslöschung
      allen jüdischen Lebens in Deutschland und Europa unternommen wurde. <br/>Unsere Inszenierung von Heines Fragment gebliebener Erzählung 
      in der <i>Stadt Bacharach mit ihrer historischen antijüdischen Pilgerstätte und heutigem Mahnmal Werner-Kapelle</i> wurde zum Kernstück des Festivals, das, 
      wie in Salzburg der alljährliche JEDERMANN, aus dem Welterbetal nicht mehr wegzudenken ist. 
      </Text>
      <Heading variant="title">
      Die Welt liegt krank zu Bett – immer noch.</Heading>
      <Text>
      Das diesjährige Festival eröffnet deshalb mit einem Prolog: ICH REDE VON DER CHOLERA – ein Kommentar zu unserer Zeit von Heine selbst.  In seinem Bericht über die Cholera 1832 in Paris beschreibt Heine vor fast 200 Jahren scharfsinnig all jene gesellschaftlichen Verwerfungen und Tragödien, die auch jetzt wieder die Welt beschäftigen – von Maskenverweigerern über abstruse Verschwörungstheorien bis hin zu aufgestapelten Leichensäcken in Einkaufspassagen.
       </Text>
       <Heading variant="title">Nein – wir FESTSPIELEN!</Heading>
      <Text>
Die weiteren Veranstaltungen umrahmen den RABBI und
greifen das Jubiläum 1700 JAHRE JÜDISCHES LEBEN IN DEUTSCHLAND (inzwischen 1701 Jahre) auf. Das
Jubiläum nimmt Bezug auf die lange und widersprüch­liche, zwischen fruchtbarster kultureller und gesellschaftlicher Anregung und schrecklichster Vernichtungspolitik chan­gierende Geschichte der Juden im Gebiet des heutigen Deutschland. Es wird zwei Gastspiele geben, DIE JUDENBUCHE von Annette von Droste-Hülshoff und IN AUSCHWITZ GAB ES KEINE VÖGEL von Monika Held und Gregor Praml. Spannende Dialoge erwarten wir bei unseren Sonntags­Podien SZENE+DIALOG die sich auf Szenen aus dem Rabbi beziehen.
      </Text>
      <Text>
      Bei aller Aufmerksamkeit auf die Sicherheit ihrer Gesundheit, mit der wir Sie empfangen werden, hoffen wir, Sie weiterhin mit den großen Fragen der Welt zu infizieren!
      </Text>
      <Text fontWeight="400">
      Die Ufer des Rheins sollen auch 2022 wieder UFER DER POESIE sein.
      </Text>
      <Text fontWeight="400">Ticketvorverkauf ab 1. Mai 2022</Text>
      </Box>
      <Flex direction="column" justifyContent="flex-start">
      <Box pt="2">
      <StaticImage 
    src="../images/jacob.jpg" alt="Rabbi" 
    
    />
    <Text variant='Bild'>Jakob Gail als Shylock</Text>
    </Box>
    
    <Box  pt="2">
       <StaticImage 
    src="../images/ufer.jpg" alt="Ufer" 
   
    />
    <Text variant='Bild'>Der Rabbi von Bacharach. Schauplatz Rheinufer.</Text>
    </Box>
     
    <Box  pt="2">
    <StaticImage 
    src="../images/schlaf.jpg" alt="Rabbi" 
    
    />
   <Text variant="Bild"> Der Rabbi von Bacharach. Schauplatz Rathaus.</Text>
    </Box>
    
    <Box  py="2"><StaticImage 
    src="../images/cholera.jpg" alt="Rabbi" 
    
    />
   <Text variant='Bild'> Heinrich Heine. Ich rede von der Cholera.</Text>
    </Box>
    
   
 </Flex>
 </SimpleGrid>
 
 </Box>
      </>
   )
} 
export default Prolog
